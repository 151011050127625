
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
interface Perm {
  type?: "C" | "R" | "U" | "D";
  list: string[];
}
interface Props {
  title: string;
  icon?: string;
  event: string;
  permissions?: Perm;
  disabled?: boolean;
}
@Component({})
export default class DotsMenu extends Vue {
  @Prop({ default: () => [] }) options!: Props[];
  clicked(event: string) {
    this.$emit(event);
  }
  getPermission(option: Props) {
    return {
      type: option.permissions?.type || "R",
      list: option.permissions?.list || [this.$constants.PRIVILEGES.NONE],
    };
  }
  get parsedOptions() {
    const noPermission = [this.$constants.PRIVILEGES.NONE];
    return (
      this.options
        ?.filter((op) => op)
        .map((op) => {
          return {
            ...op,
            permissions: {
              list: op.permissions?.list || noPermission,
              type: op.permissions?.type,
            },
          };
        }) || []
    );
  }
  get shouldShow() {
    const requirements = new Set(
      this.parsedOptions.flatMap((op) => op.permissions.list)
    );
    return (
      this.parsedOptions.length &&
      (requirements.size == 0 || this.$perms.check([...requirements]))
    );
  }
}
